import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'

import Alert from 'react-bootstrap/Alert'
import ListGroup from 'react-bootstrap/ListGroup'

import Layout from '../../components/layout'
import ResourcesNav from '../../components/resources-nav'

export default class Levels extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const resources = get(this, 'props.data.allContentfulResource.edges')
    const metaDescription = `Teach with our free ${siteTitle} lesson plans. Find the level of instruction for your classroom today!`
    return (
      <Layout pageTitle="Levels" description={metaDescription}>
        <ResourcesNav path={this.props.path} />
        <h1>Levels</h1>
        <hr />
        <p>Click on a level to access its materials.</p>
        <ListGroup>
          {resources.map(({ node }, i) => (
            <ListGroup.Item key={i} open={i == 0}>
              <Link to={`/resources/levels/${node.slug}/`}>{node.title}</Link>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Layout>
    )
  }
}

export const Query = graphql`
  query LevelsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulResource(
      filter: { collections: { eq: "levels" } }
      sort: { fields: title, order: ASC }
    ) {
      edges {
        node {
          slug
          title
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
